window.pceChart = {

    /**
     * Loaded check
     */
    loaded: function() {
        console.log("pceChart Loaded");
    },

    /**
     * Reference to chart
     */
    ref: null,

    symbol: null,

    metal: null,

    /**
     * Fetch chart data
     */
    loadData: function(metal, symbol, sourceUrl) {
        jQuery.ajax({
            url: sourceUrl,
            method: 'POST',
            dataType: 'json',
            data: {
                action: 'pinehurst-chart-data',
                symbol: symbol,
            },
            success: function(data) {
                window.pceChart.addSeries(window.pceChart.formatData(data.history), metal);
            },
        });
    },

    /**
     * Converts dataset into highchart friendly format
     *
     * @param array data
     * @return array
     */
    formatData: function(data) {
        dataset = [];
        //tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

        for(var i=0; i<data.length; i++) {
            var record = data[i];
            if(i > 3840 ){
               // usaTime = date.toLocaleString("en-US", {timeZone: "America/New_York"});
               
                console.log(record.time);
               // console.log(offset);
              
                
            }
            dataset.push([
                //Date.toLocaleString('en-US', { timeZone: 'America/New_York' }),
                Date.parse(record.time),
                parseFloat(record.close),
            ]);
        }
        return dataset;
    },

    addSeries: function(data, name) {
        window.pceChart.ref.addSeries({
          name: name,
          data: data,
          showInNavigator: true,
        });
    },

    pointFormat: function() {
        return  '<p class="tooltip-wrap"><span class="tooltip-metal">{series.name}</span>: <strong class="tooltip-metal-price">${point.y:,.2f}</strong></p>';
    },

    /**
     * Render Stock chart and load TornadoChart data
     */
    render: function(el, metal, symbol, sourceUrl) {

        if(!metal) {
            return;
        }

        window.pceChart.now();

        window.pceChart.options();


        window.pceChart.symbol = symbol;

        d = new Date();
        offset = d.getTimezoneOffset();

        

        window.pceChart.tz = Highcharts.setOptions({
            time: {
                timezoneOffset: offset,
            },
        });

        window.pceChart.ref = Highcharts.stockChart(el, {
            chart: {
                renderTo: 'container',
                type: 'area',
                styledMode: true,
            },
            credits: {
                enabled: false,
            },
            navigator: {
                adaptToUpdatedData: true,
                enabled: true,
                height: 60,
            },
            rangeSelector: {
                allButtonsEnabled: true,
                buttons: window.pceChart.buttons(),
                inputEnabled: false,
                selected: 0,
                buttonTheme: { 
                    r: 8,
                },
            },
            scrollbar: {
                enabled: false,
            },
            series: [
                {
                    name: metal,
                    data: [],
                    type: 'area',
                },
            ],
            tooltip: {
                split: false,
                shared: true,
                useHTML: true,
                valueDecimals: 2,
                pointFormat: window.pceChart.pointFormat(),
                xDateFormat: '%B %e, %Y %I:%M %p',
            },
            xAxis:{
                minRange: 24,
                dateTimeLabelFormats: window.pceChart.dateFormat(),
            },
            yAxis: {
                //min: window.pceChart.yAxisMin(metal),
                startOnTick: false,
                endOnTick: true,
            },
            plotOptions: {
                area: {
                  threshold: null,
                },
              },
              time:{

              },
        });

        window.pceChart.loadData(metal, symbol, sourceUrl);
    },

    buttons: function() {
        return [
            { type: 'day', count: 1, text: '1d', title: 'View 1 day'},
            { type: 'week', count: 1, text: '1w', title: 'View 1 week', offsetMax: -24 * 3600 * 1000 },
            { type: 'month', count: 1, text: '1m', title: 'View 1 month', offsetMax: -24 * 3600 * 1000 },
            { type: 'month', count: 3, text: '3m', title: 'View 3 months', offsetMax: -24 * 3600 * 1000 },
            { type: 'month', count: 6, text: '6m', title: 'View 6 months', offsetMax: -24 * 3600 * 1000 },
            { type: 'year', count: 1, text: '1y', title: 'View 1 year', offsetMax: -24 * 3600 * 1000 },
            { type: 'ytd', text: 'YTD', title: 'View year to date', offsetMax: -24 * 3600 * 1000 },
            { type: 'all', text: 'all', title: 'View all', offsetMax: -24 * 3600 * 1000 },
        ];
    },

    dateFormat: function() {
        return {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%I:%M %p',
            day: '%e. %b',
            week: '%e. %b',
            month: '%b \'%y',
            year: '%Y',
        }
    },

    options: function() {

        Highcharts.setOptions({
            lang: {
                rangeSelectorZoom: '',
                thousandsSep: ',',
            },
        });
    },

    yAxisMin: function(metal) {

        switch(metal.toLowerCase()) {
            case 'gold':
                return 700;
            case 'silver':
                return 9;
            case 'platinum':
                return 600;
            case 'palladium':
                return 250;
        }

        return 0;
    },

    now: function() {
        window.pceChart.renderDate();
        // refresh every minute
        setInterval(function() { window.pceChart.renderDate(); }, (1000 * 60));
    },

    renderDate: function() {
        var el      = document.getElementById('now');
        var today   = new Date();
        var date    = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate();
        el.innerHTML = window.pceChart.getDate(today) + ' ' + window.pceChart.getTime(today) + ' ' + window.pceChart.getTimeZone(today);
    },

    /**
     * Get user's timezone
     *
     * @param Date date
     * @return string
     */
    getTimeZone: function(date) {
         return date.toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
    },

    /**
     * Get current date
     *
     * @param Date date
     * @return string
     */
    getDate: function(date) {
        return (date.getMonth()+1)+'/'+date.getDate()+'/'+date.getFullYear();
    },

    /**
     * Get current time
     *
     * @param Date date
     * @return string
     */
    getTime: function(date) {
        var hour    = date.getHours();
        var minute  = date.getMinutes();
        var meridiem;

        if(12 < hour) {
            hour -= 12;
            meridiem = 'PM';
        }
        else if(hour == 12) {
            meridiem = 'PM';
        }
        else {
            meridiem = 'AM';
        }

        if(minute < 10) {
            minute = "0" + minute;
        }

        return hour + ':' + minute + ' ' + meridiem;
    },
}