import {domReady} from '@scripts/components';
import "@scripts/slick.js";
import "@scripts/highstock.js";
import "@scripts/pcechart.js";

/**
 * Run the application when the DOM is ready.
 */
domReady(() => {
    var $ = jQuery;
    $("#footer-slick-slider").slick({
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1250,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              arrows: false,
            },
          },
        ],
    });
});

/**
 * Accept module updates
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
import.meta.webpackHot?.accept(console.error);
